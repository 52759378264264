import React, {useContext, useEffect, useState} from 'react';
import {MDBAnimation, MDBCol, MDBRow, MDBSpinner, MDBTable, MDBTableBody, MDBTableHead} from 'mdbreact';

import TARow from "../../components/row/TARow";

import stackd from '../../api/stackd.api';
import Alert from '../../utils/Alert';
import ListUtils from "../../utils/ListUtils";
import StackdDataContext from "../../context/StackdDataContext";
import ToastContext from "../../context/ToastContext";

import '../../assets/css/dashboard.css'
import Button from "@material-ui/core/Button";
import storage from "../../api/storage.api";
import FilterSearch from "../../components/FilterSearch";
import FilterSelect from "../../components/FilterSelect";

export default function DashboardTA(){
    const [isLoading, setIsLoading] = useState(true);
    const [filterSearch, setFilterSearch] = useState("")
    const [filterState, setFilterState] = useState("All")
    const [sort, setSort] = useState("dateTime-decroissant")

    const [taList, setTaList] = useState([])
    const [taListDisplay, setTaListDisplay] = useState([])

    const {toast} = useContext(ToastContext)

    const deleteTA = (taId) => (event) => {
        event.stopPropagation()
        Alert.warningDelete().then(()=>{
            stackd.delete(`/ta/${taId}`).then(()=>{
                toast.fire({title: "Promesse supprimée", icon: "success"});
                let taListCopy = taList.filter((taElement)=>taElement.taId!==taId)
                setTaList(taListCopy)
            }).catch(Alert.error)
        })
    }

    const generateCSV = () => {
        const organisationId = sessionStorage.getItem("resId");
        stackd.get(`/organisations/${organisationId}/exportCSV`).then(()=>{
            window.open(`${storage}/static/xlsx/Excel_${organisationId}.xlsx`, '_blank');
        }).catch(Alert.error)
    }

    useEffect(()=>{
        const promisesList = [
            stackd.get('/ta'),
            stackd.get('/companies')
        ]

        Promise.all(promisesList).then((results)=>{
            let taListLocal = results[0].data.map(ta=> ({ ...ta, companyName: results[1].data.find(company=>company.companyId===ta.companyId)?.companyName }))
            taListLocal.map(ta => {
                return ta.companyName === undefined ? {...ta, ...{companyName: ""}} : ta
            })
            setTaList(taListLocal)
            setIsLoading(false)
        }).catch(Alert.error)
    }, [])
    useEffect(()=>{
        let list = taList
        if(filterSearch !== "")
            list = ListUtils.filterSearch(taList, "companyName", filterSearch);

        let filterStateArray = [filterState]
        if(filterState === "All")
            filterStateArray.push("Non-versée", "Versée")

        list = ListUtils.filterValue(list, "state", filterStateArray);

        const sortData = sort.split("-");

        if(sortData[0] !== "companyName") {
            list = ListUtils.sort(list, sortData[0], sortData[1])
        }else {
            list.sort(function(promiseA, promiseB) {
                const textA = promiseA.companyName.toUpperCase();
                const textB = promiseB.companyName.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            });
        }
        
        setTaListDisplay(list)
    }, [filterState, filterSearch, sort, taList])

    if(isLoading)
        return (
            <div className="mt-5">
                <MDBSpinner/>
            </div>
        )

    return(
        <StackdDataContext.Provider value={{deleteTA}}>
            <div className="dashboard" id="dashboard-ta">
                <div className="dashboard-header mb-3">
                    <MDBRow>
                        <MDBCol size="9" className="pl-0">
                            <MDBRow>
                                <MDBCol size="4">
                                    <FilterSearch
                                        placeholder="Rechercher par nom de l'entreprise"
                                        value={filterSearch}
                                        handle={setFilterSearch}
                                    />
                                </MDBCol>
                                <MDBCol size="2">
                                    <FilterSelect
                                        label="Status"
                                        value={filterState}
                                        handle={setFilterState}
                                        options={[
                                            {text: "Tout", value: "All"},
                                            {text: "Non versée", value: "Non-versée"},
                                            {text: "Versée", value: "Versée"},
                                        ]}
                                    />
                                </MDBCol>
                                <MDBCol size="3" style={{paddingRight: "10px"}}>
                                    <FilterSelect
                                        label="Trier par"
                                        value={sort}
                                        handle={setSort}
                                        options={[
                                            {text: "Date (Décroissant)", value: "dateTime-decroissant"},
                                            {text: "Montant (Décroissant)", value: "amount-decroissant"},
                                            {text: "Date (Croissant)", value: "dateTime-croissant"},
                                            {text: "Montant (Croissant)", value: "amount-croissant"},
                                            {text: "Nom d'entreprise (Croissant)", value: "companyName-croissant"},
                                            {text: "Nom d'entreprise (Décroissant)", value: "companyName-decroissant"},
                                        ]}
                                    />
                                </MDBCol>
                                <MDBCol size="2" style={{paddingRight: "10px", marginTop: "1.5rem", marginLeft: "1rem"}}>
                                    <Button color="primary" onClick={generateCSV} disabled={sessionStorage.getItem("permission") !== "Comptable"}>
                                        Export CSV
                                    </Button>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                </div>
                <div className="dashboard-content card z-depth-0dot5" style={{padding: "1rem"}}>
                    <MDBAnimation type="fadeIn">
                        <MDBTable className="table-head-bold" scrollY={true} maxHeight={"65vh"} style={{padding: "0.55rem"}}>
                            <MDBTableHead color="primary">
                                <tr>
                                    <th style={{paddingLeft: "2rem"}}>Statut</th>
                                    <th>Nom entreprise</th>
                                    <th>Montant</th>
                                    <th>Type paiement</th>
                                    <th>Date</th>
                                    <th></th>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody  >
                                {taListDisplay.map((ta, index)=>(
                                    <TARow
                                        ta={ta}
                                        key={index}
                                        filterState={{
                                            filter: [{type: "state", value:filterState}],
                                            orderBy: sort,
                                        }}
                                    />
                                ))}
                            </MDBTableBody>
                        </MDBTable>
                    </MDBAnimation>
                </div>
            </div>
        </StackdDataContext.Provider>
    )
}